/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import MobileMenu from 'containers/mobile_menu';
import { OrderWizard } from 'containers';
import {
  FormCorpkitEstatePlanner,
  FormCorpkitCorporation,
  RetrieveADoc,
  FormEntity,
  FormLLC,
  FormPartnership,
  FormNonProfit,
  ForeignQualification,
  AnnualReport,
  FileAmendment,
  FileDissolution,
  FileEin,
  FormRegisteredAgent,
  FormReserveName,
  FormApostille,
  FormUccSearch,
  FormBulkSale,
  FormConversion,
  FormCorpkitReplacementParts,
  FileDba,
  UpdateRegisteredAgent,
  PayInvoice
} from 'components';
import WebpackerReact from 'webpacker-react';

const components = {
  OrderWizard,
  FormCorpkitEstatePlanner,
  FormCorpkitCorporation,
  RetrieveADoc,
  FormEntity,
  FormLLC,
  FormPartnership,
  FormNonProfit,
  ForeignQualification,
  AnnualReport,
  FileAmendment,
  FileDissolution,
  FileEin,
  FormRegisteredAgent,
  FormReserveName,
  FormConversion,
  FormApostille,
  FormUccSearch,
  FormCorpkitReplacementParts,
  FileDba,
  FormBulkSale,
  MobileMenu,
  UpdateRegisteredAgent,
  PayInvoice
};

WebpackerReact.setup(components);

const CLASS_ATTRIBUTE_NAME = 'data-react-class';

document.addEventListener('turbolinks:load', function() {
  const { registeredComponents } = WebpackerReact;
  const toMount = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`);
  WebpackerReact.unmountComponents();

  for (let i = 0; i < toMount.length; i++) {
    const node = toMount[i];
    const className = node.getAttribute(CLASS_ATTRIBUTE_NAME);
    const component = registeredComponents[className];
    if (component) {
      node.innerHTML = '';
      WebpackerReact.render(node, component);
    } else {
      console.error(
        `webpacker-react: can not render a component that has not been registered: ${className}`
      );
    }
  }
});
