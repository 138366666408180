import React, { Suspense, lazy } from 'react';

/*
import FormCorpkitEstatePlanner from 'components/form_corpkit_estate_planner';
import FormCorpkitCorporation from 'components/form_corpkit_corporation';
import RetrieveADoc from 'components/retrieve_a_doc';
import FormEntity from 'components/form_entity';
import FormLLC from 'components/form_llc';
import FormPartnership from 'components/form_partnership';
import FormNonProfit from 'components/form_non_profit';
import ForeignQualification from 'components/foreign_qualification';
import AnnualReport from 'components/annual_report';
import FileAmendment from 'components/file_amendment';
import FileDissolution from 'components/file_dissolution';
import FileEin from 'components/file_ein';
import FormRegisteredAgent from 'components/form_registered_agent';
import FormReserveName from 'components/form_reserve_name';
import FormApostille from 'components/form_apostille';
import FormUccSearch from 'components/form_ucc_search';
import FormBulkSale from 'components/form_bulk_sale';
import FormConversion from 'components/form_conversion';
import FormCorpkitReplacementParts from 'components/form_corpkit_replacement_parts';
import FileDba from 'components/file_dba';
*/

import './passQueryParamToIframe';

const [
  LazyFormCorpkitEstatePlanner,
  LazyFormCorpkitCorporation,
  LazyRetrieveADoc,
  LazyFormEntity,
  LazyFormLLC,
  LazyFormPartnership,
  LazyFormNonProfit,
  LazyForeignQualification,
  LazyAnnualReport,
  LazyFileAmendment,
  LazyFileDissolution,
  LazyFileEin,
  LazyFormRegisteredAgent,
  LazyFormReserveName,
  LazyFormApostille,
  LazyFormUccSearch,
  LazyFormBulkSale,
  LazyFormConversion,
  LazyFormCorpkitReplacementParts,
  LazyFileDba,
  LazyUpdateRegisteredAgent,
  LazyPayInvoice
] = [
  'form_corpkit_estate_planner',
  'form_corpkit_corporation',
  'retrieve_a_doc',
  'form_entity',
  'form_llc',
  'form_partnership',
  'form_non_profit',
  'foreign_qualification',
  'annual_report',
  'file_amendment',
  'file_dissolution',
  'file_ein',
  'form_registered_agent',
  'form_reserve_name',
  'form_apostille',
  'form_ucc_search',
  'form_bulk_sale',
  'form_conversion',
  'form_corpkit_replacement_parts',
  'file_dba',
  'update_registered_agent',
  'pay_invoice'
].map(key => lazy(() => import(`./${key}`)));

const LoadingScreen = () => (
  <div className="lazy__loading">
    <svg
      className="spinner"
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </div>
);

const Loading = ({ children }) => (
  <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
);

export const FormCorpkitEstatePlanner = props => (
  <Loading>
    <LazyFormCorpkitEstatePlanner {...props} />
  </Loading>
);
export const FormCorpkitCorporation = props => (
  <Loading>
    <LazyFormCorpkitCorporation {...props} />
  </Loading>
);
export const RetrieveADoc = props => (
  <Loading>
    <LazyRetrieveADoc {...props} />
  </Loading>
);
export const FormEntity = props => (
  <Loading>
    <LazyFormEntity {...props} />
  </Loading>
);
export const FormLLC = props => (
  <Loading>
    <LazyFormLLC {...props} />
  </Loading>
);
export const FormPartnership = props => (
  <Loading>
    <LazyFormPartnership {...props} />
  </Loading>
);
export const FormNonProfit = props => (
  <Loading>
    <LazyFormNonProfit {...props} />
  </Loading>
);
export const ForeignQualification = props => (
  <Loading>
    <LazyForeignQualification {...props} />
  </Loading>
);
export const AnnualReport = props => (
  <Loading>
    <LazyAnnualReport {...props} />
  </Loading>
);
export const FileAmendment = props => (
  <Loading>
    <LazyFileAmendment {...props} />
  </Loading>
);
export const FileDissolution = props => (
  <Loading>
    <LazyFileDissolution {...props} />
  </Loading>
);
export const FileEin = props => (
  <Loading>
    <LazyFileEin {...props} />
  </Loading>
);
export const FormRegisteredAgent = props => (
  <Loading>
    <LazyFormRegisteredAgent {...props} />
  </Loading>
);
export const FormReserveName = props => (
  <Loading>
    <LazyFormReserveName {...props} />
  </Loading>
);
export const FormApostille = props => (
  <Loading>
    <LazyFormApostille {...props} />
  </Loading>
);
export const FormUccSearch = props => (
  <Loading>
    <LazyFormUccSearch {...props} />
  </Loading>
);
export const FormBulkSale = props => (
  <Loading>
    <LazyFormBulkSale {...props} />
  </Loading>
);
export const FormConversion = props => (
  <Loading>
    <LazyFormConversion {...props} />
  </Loading>
);
export const FormCorpkitReplacementParts = props => (
  <Loading>
    <LazyFormCorpkitReplacementParts {...props} />
  </Loading>
);
export const FileDba = props => (
  <Loading>
    <LazyFileDba {...props} />
  </Loading>
);
export const UpdateRegisteredAgent = props => (
  <Loading>
    <LazyUpdateRegisteredAgent {...props} />
  </Loading>
);
export const PayInvoice = props => (
  <Loading>
    <LazyPayInvoice {...props} />
  </Loading>
);
