import React, { Component } from 'react';

export class MobileMenu extends Component {
  state = {
    expandedOption: null,
    previousExpandedOption: [],
    searchValue: ''
  };

  componentDidMount() {
    document.addEventListener('collapseAll', this._collapseAll);
  }

  componentWillUnmount() {
    document.removeEventListener('collapseAll', this._collapseAll);
  }

  _handleOnTypeSearchValue = evt => {
    this.setState({ searchValue: evt.target.value });
  };

  _handleExpandMenu = evt => {
    // prevents the native event from firing off the modal
    evt.nativeEvent.stopImmediatePropagation();
    const { options } = this.props;

    const {
      expandedOption: currentExpandedOption,
      previousExpandedOption
    } = this.state;

    const menuOptions = currentExpandedOption
      ? currentExpandedOption.options
      : options;

    const { name: optionName } = evt.target.dataset;
    const expandedOption = menuOptions.find(
      option => option.name === optionName
    );

    if (currentExpandedOption) {
      previousExpandedOption.push(currentExpandedOption);
    }

    this.setState({
      expandedOption,
      previousExpandedOption
    });
  };

  _backToPreviousExpandedMenu = () => {
    const { previousExpandedOption } = this.state;

    const expandedOption = previousExpandedOption.pop();

    this.setState({
      expandedOption
    });
  };

  _collapseAll = () => {
    this.setState({
      expandedOption: null,
      previousExpandedOption: []
    });
  };

  render() {
    const { options } = this.props;
    const { expandedOption } = this.state;

    return (
      <ul className="list list-container">
        {!expandedOption ? (
          options.map((option, index) => (
            <li key={index}>
              {option.link ? (
                <a
                  href={option.link}
                  className="js-menu-group item"
                  onClick={option.options ? this._handleExpandMenu : null}
                  data-name={option.name}
                  data-turbolinks={!option.disable_turbolinks}
                  id={option.id}
                >
                  {option.name}
                </a>
              ) : (
                <strong
                  className="js-menu-group item-group"
                  onClick={option.options ? this._handleExpandMenu : null}
                  data-name={option.name}
                  id={option.id}
                  data-turbolinks={!option.disable_turbolinks}
                >
                  {option.name}
                </strong>
              )}
            </li>
          ))
        ) : (
          <li>
            <strong
              className="js-menu-group item-group expanded"
              onClick={this._backToPreviousExpandedMenu}
              data-name={expandedOption.name}
              id={expandedOption.id}
            >
              {expandedOption.name}
            </strong>
            <ul className="list">
              {expandedOption.options.map((option, index) => (
                <li key={index}>
                  {option.link ? (
                    <a
                      href={option.link}
                      className={`js-menu-group ${
                        option.options ? 'item-group' : 'item'
                      }`}
                      onClick={option.options ? this._handleExpandMenu : null}
                      data-name={option.name}
                      data-turbolinks={!option.disable_turbolinks}
                      id={option.id}
                    >
                      {option.name}
                    </a>
                  ) : (
                    <strong
                      className={`js-menu-group ${
                        option.options ? 'item-group' : 'item'
                      }`}
                      onClick={option.options ? this._handleExpandMenu : null}
                      data-name={option.name}
                      id={option.id}
                      data-turbolinks={!option.disable_turbolinks}
                    >
                      {option.name}
                    </strong>
                  )}
                </li>
              ))}
            </ul>
          </li>
        )}
        <li>
          <a
            href="#"
            className="item-group no-caret js-display-search-items mobile search"
          >
            <i className="sundoc-search icon" />
            SEARCH
          </a>
          <div className="item-group no-caret js-search-items mobile mui--hide search">
            <form
              className="search-items mobile clearfix"
              action="/search"
              method="GET"
            >
              <button
                type="submit"
                name=""
                value=""
                className="search-field__submit "
                data-search-submit-on-click
              >
                <i className="sundoc-search icon" />
              </button>
              <div className="search-items__field mobile">
                <input
                  type="text"
                  name="containing_words"
                  value={this.state.searchValue}
                  id="js-search-item-input-mobile"
                  className="search-field__text-field js-search-item-input"
                  placeholder="Search"
                  onChange={this._handleOnTypeSearchValue}
                  data-search-submit-on-enter
                />
              </div>
            </form>
          </div>
        </li>
      </ul>
    );
  }
}

export default MobileMenu;
