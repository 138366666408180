import $ from 'jquery'

$(function() {
    window.addEventListener('message', receiveMessageFromIframe)
})

let sentParentUrl = false
let sentLeadId = false

function sendMessageToIframe(message) {
    const iframe = $('iframe')[0];
    iframe.contentWindow.postMessage(message, "*")
}

function getQueryParamValue(paramName) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
}

function receiveMessageFromIframe(event) {
    console.log('Recieved message from iframe.')
    // Check the origin of the message for security
    // if (event.origin !== envVars['PRODUCT_IFRAME_BASE_URL']
    // ) {
    //     return
    // }

    const receivedData = event.data;
    if (receivedData === 'requesting_entity_name') {
        const entityName = getQueryParamValue('entityName')

        if (entityName !== null) {
            // sendMessageToIframe({
            //     'entityName': entityName
            // })
        }
    }

    if (receivedData === 'requesting_parent_url' && !sentParentUrl) {
        console.log('received request for parent url')
        sendMessageToIframe({
            'parentUrl': window.location.origin + window.location.pathname
        })
        sentParentUrl = true
    }

    if (receivedData === 'requesting_lead_id' && !sentLeadId) {
        const leadId = getQueryParamValue('lead_id')
        if (leadId !== null) {
            sendMessageToIframe({
                'leadId': leadId
            })
        }
        sentLeadId = true
    }
}
