import React, { Suspense, lazy } from 'react';

const Loading = ({ children }) => (
  <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
);

const LazyOrderWizard = lazy(() => import('./order_wizard'));

export const OrderWizard = props => (
  <Loading>
    <LazyOrderWizard {...props} />
  </Loading>
);
